import type { LoggerConfigType } from '@/types/LoggerConfigType';
import type { StickyFieldsType } from '@/types/StickyFieldsType';

export const SotaLoggerConfig: LoggerConfigType = {
  templateId: 'SOTA',
  templateDescription: 'HAMRS SOTA Template',
  toolbarItems: ['TIME', 'FREQUENCY', 'MODE', 'TX_POWER', 'MY_CALLSIGN', 'MY_SUMMIT', 'MY_LOCATION'],
  toolbarPosition: 'TOP',
  panels: ['QSO_MAP'],
  tableColumns: ['qsoDateTime', 'call', 'rstSent', 'rstRcvd', 'state', 'freq', 'band', 'mode', 'sotaRef', 'qrzcomQsoUploadStatus'],
  dupeCheckFields: ['call', 'mode', 'band', 'sotaRef', 'qsoDate'],
  stickyFields: [
    "freq",
    "band",
    "mySotaRef",
    "myGridsquare",
    "mode",
    "txPwr",
    "myCnty",
    "myState",
    "operator",
    "stationCallsign",
  ] as unknown as StickyFieldsType[],
  fieldRows: [
    {
      columns: { base: 4 },
      fields: [
        { component: 'TheirCall' },
        { component: 'RstSent' },
        { component: 'RstReceived' },
        { component: 'TheirSummit' }]
    },
    {
      columns: { base: 2 },
      fields: [
        { component: 'Comments' },
        { component: 'Notes' },
      ]
    },
    {
      columns: { base: 12 },
      fields: [
        { component: 'TheirName', columns: { base: 3 } },
        { component: 'TheirCity', columns: { base: 3 } },
        { component: 'TheirState', columns: { base: 2 } },
        { component: 'TheirCounty', columns: { base: 2 } },
        { component: 'TheirGrid', columns: { base: 2 } }
      ]
    },
    {
      classes: "flex justify-end",
      fields: [
        { component: 'ClearButton' },
        { component: 'SaveButton' },
      ]
    }
  ],
}

export default SotaLoggerConfig;