import type {
  ColumnLayoutPropsType,
  QsoTableColumnsType,
  StickyFieldsType,
  ToolbarFields,
  LoggerPanels,
  LogbookTemplatePouchData,
  FieldRow,
  QsoType,
} from '@/types';
interface LogbookTemplateImportExportPayload {
  title: string;
  createdAt: Date;
  updatedAt: Date;
  description: string;
  toolbarItems: ToolbarFields[];
  toolbarPosition: 'TOP' | 'BOTTOM';
  tableColumns: QsoTableColumnsType[];
  stickyFields: StickyFieldsType[];
  label: string;
  panels: LoggerPanels[];
  fieldRows: FieldRow[];
}
import LogbookTemplatePouchRepo from '@/repos/LogbookTemplatePouchRepo';
import type { LoggerConfigType } from '@/types/LoggerConfigType';
interface FormkitField {
  $cmp: string;
  props?: {
    columns?: ColumnLayoutPropsType;
  } & {
    [key: string]: string | number | ColumnLayoutPropsType;
  };
  label?: string;
}

export type { LogbookTemplatePouchData };

export class LogbookTemplate {
  _id: string;
  title: string;
  createdAt: Date;
  updatedAt: Date;
  description: string;
  toolbarItems: ToolbarFields[];
  toolbarPosition: 'TOP' | 'BOTTOM';
  tableColumns: QsoTableColumnsType[];
  stickyFields: StickyFieldsType[];
  dupeCheckFields: Array<keyof QsoType>;
  label: string;
  panels: LoggerPanels[];
  fieldRows: FieldRow[] = [];

  constructor(attrs: Partial<LogbookTemplate> = {}) {
    this._id = attrs._id || '';
    this.description = attrs.description || '';
    this.title = attrs.title || 'New Logbook Template';
    this.tableColumns = attrs.tableColumns || [];
    this.createdAt = attrs.createdAt ? new Date(attrs.createdAt) : new Date();
    this.updatedAt = attrs.updatedAt ? new Date(attrs.updatedAt) : new Date();
    this.stickyFields = attrs.stickyFields || [];
    this.dupeCheckFields = attrs.dupeCheckFields || [];
    this.toolbarPosition = attrs.toolbarPosition || 'TOP';
    this.toolbarItems = attrs.toolbarItems || [];
    this.panels = attrs.panels || [];
    this.label = attrs.label || 'CUSTOM';
    this.fieldRows = attrs.fieldRows || LogbookTemplate.defaultFieldRows();
  }

  // Getter returns the field rows array

  public async save(): Promise<LogbookTemplate> {
    return await LogbookTemplatePouchRepo.update(this.pouchDocument);
  }

  public get pouchDocument(): Partial<LogbookTemplatePouchData> {
    return {
      _id: this._id,
      title: this.title,
      createdAt: this.createdAt.toISOString(),
      updatedAt: this.updatedAt.toISOString(),
      description: this.description,
      toolbarItems: this.toolbarItems,
      toolbarPosition: this.toolbarPosition,
      tableColumns: this.tableColumns,
      stickyFields: this.stickyFields,
      label: this.label,
      fieldRows: this.fieldRows,
      panels: this.panels,
      dupeCheckFields: this.dupeCheckFields
    };
  }
  public static defaultFieldRows(): FieldRow[] {
    return [
      {
        columns: { base: 3 },
        fields: [
          { component: 'TheirCall' },
          { component: 'RstSent' },
          { component: 'RstReceived' }
        ]
      },
      {
        classes: "flex justify-end",
        fields: [
          { component: 'ClearButton' },
          { component: 'SaveButton' },
        ]
      }
    ]
  }

  public get formkitSchema(): any {
    if (!this.fieldRows || this.fieldRows.length === 0) {
      return [];
    }
    const rows = this.fieldRows.map(row => {
      return {
        $cmp: "Row",
        props: {
          columns: row.columns,
          ...(row.classes ? { class: row.classes } : {})
        },
        children: row.fields.map(field => {
          const fieldInput: FormkitField = { $cmp: field.component }
          if (field.props) {
            fieldInput.props = { ...fieldInput.props, ...field.props }
          }
          if (field.columns) {
            fieldInput.props = {
              ...fieldInput.props,
              columns: field.columns
            }
          }
          if (field.label) {
            fieldInput.label = field.label
          }
          return fieldInput
        })
      }
    })
    return rows
  }

  public get loggerConfig(): LoggerConfigType {
    return {
      templateId: 'CUSTOM',
      templateDescription: this.description,
      toolbarItems: this.toolbarItems,
      toolbarPosition: this.toolbarPosition,
      tableColumns: this.tableColumns,
      stickyFields: this.stickyFields,
      fieldRows: this.fieldRows,
      panels: this.panels,
      dupeCheckFields: this.dupeCheckFields
    }
  }

  public async delete(): Promise<LogbookTemplate> {
    return await LogbookTemplatePouchRepo.delete(this._id);
  }

  public static async findAll(): Promise<LogbookTemplate[]> {
    return await LogbookTemplatePouchRepo.findAll();
  }

  public static async findById(id: string): Promise<LogbookTemplate> {
    return await LogbookTemplatePouchRepo.findOne(id);
  }

  public static async create(attrs: Partial<LogbookTemplate>): Promise<LogbookTemplate> {
    return await LogbookTemplatePouchRepo.create(attrs);
  }

  public toEncodedString(): string {
    const toEncode = {
      title: this.title,
      description: this.description,
      toolbarItems: this.toolbarItems,
      toolbarPosition: this.toolbarPosition,
      tableColumns: this.tableColumns,
      stickyFields: this.stickyFields,
      label: this.label,
      fieldRows: this.fieldRows,
      panels: this.panels,
      dupeCheckFields: this.dupeCheckFields
    }
    return btoa(JSON.stringify(toEncode));
  }
  public updateFromEncodedString(encodedString: string): LogbookTemplate {
    const decoded = JSON.parse(atob(encodedString));
    this.title = decoded.title
    this.description = decoded.description
    this.toolbarItems = decoded.toolbarItems
    this.toolbarPosition = decoded.toolbarPosition
    this.tableColumns = decoded.tableColumns
    this.stickyFields = decoded.stickyFields
    this.label = decoded.label
    this.fieldRows = decoded.fieldRows
    this.panels = decoded.panels
    this.dupeCheckFields = decoded.dupeCheckFields
    return this
  }
}

export default LogbookTemplate;