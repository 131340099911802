import type { LookupServiceProviderType, LookupServiceResponseType } from "@/types";
import type { LookupServiceInterface } from "@/interfaces/LookupServiceInterface";
import { lookupsLogger } from "@/utils/loggers";

import HamDbService from "./HamDbService";
import HamQthService from "./HamQthService";
import QrzService from "./QrzService";

type ProviderConstructor = new () => LookupServiceInterface;

const providerConstructors: [LookupServiceProviderType, ProviderConstructor][] = [
  ["HAMDB", HamDbService],
  ["HAMQTH", HamQthService],
  ["QRZ", QrzService],
];

class LookupService implements LookupServiceInterface {
  public adapter: LookupServiceInterface;
  private static providerMap = new Map(providerConstructors);

  constructor(providerName: LookupServiceProviderType) {
    if (!providerName) {
      throw new Error("Service type must be provided");
    }
    this.adapter = this.findAdapter(providerName);
  }

  get requiresAuthentication(): boolean {
    return this.adapter.requiresAuthentication;
  }
  get providerName(): LookupServiceProviderType {
    return this.adapter.providerName;
  }
  get providesGridsquares(): boolean {
    return this.adapter.providesGridsquares;
  }

  private findAdapter(
    providerName: LookupServiceProviderType
  ): LookupServiceInterface {
    const provider = LookupService.providerMap.get(providerName);
    if (!provider) {
      throw new Error(`Service ${providerName} not found`);
    }
    return new provider();
  }

  public async lookupCallsign(
    callsign: string,
    sessionKey: string
  ): Promise<LookupServiceResponseType> {
    return this.adapter.lookupCallsign(callsign, sessionKey);
  }

  public async authenticate(
    username: string,
    password: string
  ): Promise<string> {
    if (!this.adapter.requiresAuthentication || !this.adapter.authenticate) {
      throw new Error(
        `Service ${this.adapter.providerName} does not require authentication`
      );
    }
    lookupsLogger("Authenticating with", this.adapter.providerName);
    return this.adapter.authenticate(username, password);
  }

  async validateCredentials(
    username: string,
    password: string
  ): Promise<{ isValid: boolean; isSet: boolean }> {
    const isSet = username !== "" && password !== "";

    if (!isSet) {
      return { isValid: false, isSet };
    }

    try {
      lookupsLogger("Validating credentials for", this.adapter.providerName);
      await this.authenticate(username, password);
      return { isValid: true, isSet };
    } catch (error) {
      return { isValid: false, isSet };
    }
  }
}

export default LookupService;
