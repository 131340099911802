import type { LookupServiceInterface } from "@/interfaces/LookupServiceInterface";
import LookupServiceResponseTransformer from "@/transformers/LookupServiceResponseTransformer";
import type { LookupServiceResponseType, LookupServiceProviderType } from "@/types";
import { XMLParser } from "fast-xml-parser";
import {
  ProviderCallsignNotFoundError,
  ProviderAuthenticationError,
  ProviderSessionExpiredError,
} from "@/utils/errors/LookupServiceErrors";

const QRZ_ENDPOINT = "https://xmldata.qrz.com/xml/current";
const parser = new XMLParser();

class QrzService implements LookupServiceInterface {
  readonly providerName: LookupServiceProviderType = "QRZ";
  readonly requiresAuthentication: boolean = true;
  providesGridsquares: boolean = false;

  sessionKey: string;

  constructor(sessionKey: string = "") {
    this.sessionKey = sessionKey;
  }

  public async authenticate(
    username: string,
    password: string
  ): Promise<string> {
    if (!username || !password) {
      throw new ProviderAuthenticationError(this.providerName, "Missing username or password")
    }
    const response = await fetch(
      `${QRZ_ENDPOINT}?username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`
    );
    const xmlData = await response.text();
    const parsedXml = this.parseXmlResponse(xmlData);

    this.handleErrors(parsedXml);

    if (this.hasSessionKey(parsedXml)) {
      this.sessionKey = this.getSessionKey(parsedXml);
      // Check if user is a subscriber
      this.providesGridsquares = parsedXml?.QRZDatabase?.Session?.SubExp !== "non-subscriber";
    }
    return this.sessionKey;
  }

  normalizeCallsignQuery(callsign: string): string {
    const [prefix, suffix] = callsign.toUpperCase().split("/");
    if (suffix && ["P", "M"].includes(suffix)) {
      return prefix
    } else {
      return encodeURIComponent(callsign);
    }
  }
  public async lookupCallsign(callsign: string): Promise<any> {
    const normalizedCallsign = this.normalizeCallsignQuery(callsign);
    const response = await fetch(
      `${QRZ_ENDPOINT}?s=${this.sessionKey}&callsign=${normalizedCallsign}`
    );
    const xmlData = await response.text();
    const parsedXml = this.parseXmlResponse(xmlData)

    this.handleErrors(parsedXml);

    const normalizeResponse = this.normalizeResponse(parsedXml);
    return normalizeResponse;
  }
  private parseXmlResponse(xmlData: string) {
    const parsedXml = parser.parse(xmlData);
    return parsedXml;
  }

  private handleErrors(response: any): void {
    if (response?.QRZDatabase?.Session?.Error) {
      this.handleQrzError(response.QRZDatabase.Session.Error);
    }
  }

  private hasSessionKey(response: any): boolean {
    return !!this.getSessionKey(response);
  }
  private getSessionKey(response: any): string {
    return response?.QRZDatabase?.Session?.Key;
  }

  private handleQrzError(errorMessage: string): Error {
    if (errorMessage.includes("Not found:")) {
      throw new ProviderCallsignNotFoundError(this.providerName, errorMessage);
    }
    switch (errorMessage) {
      case "Username / password required":
      case "Username/password incorrect":
        throw new ProviderAuthenticationError(this.providerName, errorMessage);
      case "Invalid session key":
        throw new ProviderSessionExpiredError(this.providerName, errorMessage);
      default:
        throw new Error(errorMessage);
    }
  }

  private normalizeResponse(response: any): LookupServiceResponseType {
    return LookupServiceResponseTransformer(response, this.providerName);
  }
}

export default QrzService
