import type { LoggerConfigType, ToolbarFields, QsoTableColumnsType, LoggerPanels, FieldRow } from '@/types/LoggerConfigType';
import type { StickyFieldsType } from '@/types/StickyFieldsType';
import type { QsoType, LogbookType, ColumnLayoutPropsType } from '@/types';
import LoggerConfigRegistry from './LoggerConfigRegistry';
import LogbookTemplate from './LogbookTemplate';
import { normalizeQso } from "@/utils/normalizeQso";

export class Logger {
  private config!: LoggerConfigType;
  public logbook: LogbookType;
  private isInitialized: boolean = false;
  private static configRegistry = LoggerConfigRegistry.getInstance();

  /**
   * Factory method that initializes and returns a fully configured Logger instance.
   * @param logbook The logbook to initialize with
   * @returns A Promise resolving to a fully initialized Logger
   */

  public static async init(logbook: LogbookType): Promise<Logger> {
    const logger = new Logger(logbook);
    await logger.initializeConfig();
    return logger;
  }

  // Constructor now just sets initial state
  constructor(logbook: LogbookType) {
    this.logbook = logbook;
  }

  // Method to check if logger is fully initialized
  public get initialized(): boolean {
    return this.isInitialized;
  }

  // Getters for common config properties
  public get dupeCheckFields(): Array<keyof QsoType> {
    return this.config.dupeCheckFields;
  }

  public get toolbarItems(): ToolbarFields[] {
    return this.config.toolbarItems ?? [];
  }

  public get toolbarPosition(): 'TOP' | 'BOTTOM' {
    return this.config.toolbarPosition;
  }

  public get tableColumns(): QsoTableColumnsType[] {
    return this.config.tableColumns;
  }

  public get panels(): LoggerPanels[] {
    return this.config.panels;
  }

  public get stickyFields(): StickyFieldsType[] {
    return this.config.stickyFields;
  }

  public get fieldRows(): FieldRow[] {
    return this.config.fieldRows;
  }

  public get templateId(): string {
    return this.config.templateId;
  }

  public get templateDescription(): string {
    return this.config.templateDescription;
  }

  // Now private and returns a Promise
  private async initializeConfig(): Promise<void> {
    if (this.logbook.template === 'CUSTOM' && this.logbook.templateId) {
      // For custom templates, try to load from template ID
      try {
        const customTemplate = await LogbookTemplate.findById(this.logbook.templateId);
        this.config = customTemplate.loggerConfig;
      } catch {
        // Fallback to GENERIC if custom template fails to load
        this.config = Logger.configRegistry.getConfig('GENERIC');
      }
    } else {
      // For built-in templates, use template name or fallback to GENERIC
      const templateId = this.logbook.template === 'CUSTOM' ? 'GENERIC' : this.logbook.template;
      this.config = Logger.configRegistry.getConfig(templateId);
    }

    this.validateConfig(this.config);
    this.isInitialized = true;
  }

  private validateConfig(config: LoggerConfigType): void {
    if (!config.templateId || !config.fieldRows) {
      throw new Error('Invalid configuration: must include templateId and fieldRows');
    }
  }

  public static registerConfig(config: LoggerConfigType): void {
    Logger.configRegistry.registerConfig(config);
  }

  public getConfig(): LoggerConfigType {
    return this.config;
  }

  public setConfig(config: LoggerConfigType): void {
    this.validateConfig(config);
    this.config = config;
    // Only register if it has a templateId
    if (config.templateId) {
      Logger.configRegistry.registerConfig(config);
    }
  }

  public formkitSchema(): any {
    const rows = this.config.fieldRows.map(row => {
      return {
        $cmp: "Row",
        props: {
          columns: row.columns,
          ...(row.classes ? { class: row.classes } : {})
        },
        children: row.fields.map(field => {
          const fieldInput: FormkitField = { $cmp: field.component }
          if (field.props) {
            fieldInput.props = field.props
          }
          if (field.columns) {
            fieldInput.props = {
              ...fieldInput.props,
              columns: field.columns
            }
          }
          if (field.label) {
            fieldInput.label = field.label
          }
          return fieldInput
        })
      }
    })
    return rows
  }

  /**
   * Creates a unique key for a QSO based on the configured dupe check fields
   * @param qso The QSO to create a key for
   * @returns A string key representing the unique combination of fields
   */
  private createDupeKey(qso: QsoType): string {
    const normalizedQso = normalizeQso(qso);
    return this.config.dupeCheckFields
      .map(field => normalizedQso[field] || '')
      .join('|');
  }

  /**
   * Finds all duplicate QSOs in an array
   * Time complexity: O(n) where n is the number of QSOs
   * Space complexity: O(n) for the hash map
   * @param qsos Array of QSOs to check for duplicates
   * @returns Array of duplicate QSOs
   */
  public findDuplicates(qsos: QsoType[]): QsoType[] {
    if (this.config.dupeCheckFields.length === 0) {
      return [];
    }
    const duplicates: QsoType[] = [];
    const qsoMap = new Map<string, QsoType[]>();

    // Need at least 2 QSOs to have duplicates
    if (qsos.length < 2) {
      return duplicates;
    }

    // Process each QSO once, O(n)
    for (const qso of qsos) {
      const key = this.createDupeKey(qso);
      const existingQsos = qsoMap.get(key) || [];

      if (existingQsos.length > 0) {
        // If this is our first duplicate for this key, add all existing QSOs
        if (!duplicates.some(d => existingQsos.includes(d))) {
          duplicates.push(...existingQsos);
        }
        duplicates.push(qso);
      }

      qsoMap.set(key, [...existingQsos, qso]);
    }

    return duplicates;
  }

  /**
   * Returns an array of IDs for duplicate QSOs
   * @param qsos Array of QSOs to check for duplicates
   * @returns Array of duplicate QSO IDs
   */
  public duplicateQsoIds(qsos: QsoType[]): string[] {
    return this.findDuplicates(qsos)
      .map(qso => qso._id)
      .filter((id): id is string => id !== undefined);
  }

  /**
   * Checks if two QSOs are duplicates based on configured fields
   * This method is provided for testing and external use
   * For bulk operations, use findDuplicates instead
   * @param qso1 First QSO to compare
   * @param qso2 Second QSO to compare
   * @returns boolean indicating if QSOs are duplicates
   */
  public dupeChecker(qso1: QsoType, qso2: QsoType): boolean {
    return this.createDupeKey(qso1) === this.createDupeKey(qso2);
  }

  public containsPotaFields(): boolean {
    if (this.logbook.template === 'POTA') {
      return true
    }

    if (this.toolbarItems.includes('MY_PARK')) {
      return true;
    }

    if (this.panels.includes('POTA_SPOTS')) {
      return true;
    }

    for (const row of this.fieldRows) {
      for (const field of row.fields) {
        if (
          field.component === 'TheirPark' ||
          (field.props && 'potaRef' in field.props) ||
          (field.props && 'myPotaRef' in field.props)
        ) {
          return true;
        }
      }
    }
    return false;
  }
  public containsSotaFields(): boolean {
    if (this.logbook.template === 'SOTA') {
      return true
    }

    if (this.toolbarItems.includes('MY_SUMMIT')) {
      return true;
    }

    // if (this.panels.includes('POTA_SPOTS')) {
    //   return true;
    // }

    for (const row of this.fieldRows) {
      for (const field of row.fields) {
        if (
          field.component === 'TheirSummit' ||
          (field.props && 'sotaRef' in field.props) ||
          (field.props && 'mySotaRef' in field.props)
        ) {
          return true;
        }
      }
    }
    return false;
  }
}

interface FormkitField {
  $cmp: string;
  props?: {
    columns?: ColumnLayoutPropsType;
  } & {
    [key: string]: string | number | ColumnLayoutPropsType;
  };
  label?: string;
}

export default Logger;